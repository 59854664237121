import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-noshtno-vijdane-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-noshtno-vijdane-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-noshtno-vijdane-header.jpg"
import LuteinComplexXL from "./../../images/lutein-noshtno-vijdane-header.jpg"

import Lutein from "./../../images/lutein-prevantivno-Lutein.jpg"

const LuteinNoshtno = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-noshtno" }}>
    <Seo title="Изследване: Лутеина би могъл да подобри нощното виждане" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ИЗСЛЕДВАНЕ: ЛУТЕИНА БИ МОГЪЛ ДА ПОДОБРИ ...
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Изследване: Лутеина би могъл да подобри нощното виждане"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Изследване: Лутеина би могъл да подобри нощното виждане
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Дневна доза лутеин би могла да подобри зрението и да помогне при
            шофиране през нощта например, твърдят данни от произволно проведено,
            плацебо-контролирано изследване.
          </p>
          <p>
            Едногодишното проучване, проведено от изследователи от китайския
            университет Шангхай Жиаотонг, разкрива, че дневна доза от 20
            милиграма лутеин се свързва със значително подобрение към
            чувствителността към контраст и облясъци.
          </p>
          <p>
            „Въпреки, че резултатите в изследването не подкрепят директна връзка
            между орално приемане на добавки и промени във визуалната острота,
            сегашните открития предоставят убедителни доказателства, че приемане
            на лутеин може значително да подобри визуалната производителност и
            качеството на живот свързано със зрението при ограничена светлина,”
            пишат те в журнала Nutrition.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>Лутеин</h4>
          <p>
            Каротеноидите лутеин и зеаксантин са жизненоважни за здравето на
            очите, като и двата са открити в големи количества в макулата,
            жълто петно от около 5 милиметра диаметър, намиращо се в ретината.
          </p>
          <p>
            Тези съединения са единствените каротеноиди способни да филтрират
            вредната синя светлина, която би могла да повреди клетките на очите,
            пръчиците и колбичките. Колбичките се ползват за виждане в добре
            осветени условия (фотопично/дневно зрение). Окото превключва към
            причиците за виждане при много слабо осветление (скотопично зрение).
            Нощем също е възможно ползването на комбинация от пръчици и
            колбички, което се нарича мезопично зрение).
          </p>
          <p>
            Тънън макулен пигмент би позволил на синята светлина да проникне и
            да унищожи клетките. Поддържането на високо ниво от двата
            каротеноида и чрез тях, макулния пигмент, е валиден подход към
            поддържането на очното здраве и намаляване на риска от заболявания,
            като Възрастовосвързана Макулна Дегенерация (ВСМД).
          </p>
          <p>
            Китайските учени обясняват, че въпреки, че вече се знае влиянието на
            лутеина върху зрителната производителност, „все още имаме много да
            научим за ефекта му при различните популации, например шофьорите”.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>Подробности за проучването</h4>
          <p>
            Проведено е върху 120 човека прекарващи средно по 10 часа на ден
            шофирайки, през 2 годишния период на проучването. Участниците
            произволно са избрани да получават или добавка с лутеин или плацебо
            за една година. Изследователите измерват визуална острота,
            концентрация на лутеин в серума, оптична плътност на макулния
            пигмент и визуална производителност, на равни интервали по време на
            проучването.
          </p>
          <p>
            Съдържанието на лутеин в добавката е сертифицирано от Държавната
            Администрация по Храните и Лекарствата на Китай.
          </p>
        </Col>
        <Col sm={7}>
          <p>
            Резултатите показват, че в групата с лутеин се наблюдава подобрена
            зрителна острота, но това не достига до значителна разлика. От друга
            страна, нивата на лутеин в серума и централната оптична плътност на
            макулния пигмент са се увеличили значително в лутеин групата за
            разлика от плацебо групата, където не са се променили. Допълнително,
            учените наблюдават „значително подобрение в контрастта и
            чувствителността към отблясъци, особено в мезопичното състояние”.
          </p>
          <p>
            „Подобрението в чувствителността към контраста и отблясъците е
            статистически значително, особено в мезопичното състояние, което
            означава, че приемането на добавки с лутеин би помогнало шофирането
            през нощта и други задачи извършвани при слабо осветление,”
            заключават те.
          </p>
          <p>
            Източник: Nutrition, 10.1016/j.nut.2012.10.017 Автори: Y. Yao, Q-h,
            Qui, X-W. Wu, Z-y. Cai, S. Xu, X-q. Liang
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={Lutein} />
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinNoshtno
